import { useState, useEffect } from "react";
import axios from "axios";

import Note from "./../components/Note";
import style from "./../styles/Blog.module.css";

const Blog = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true); // 로딩 상태 추가

  const encoded = encodeURIComponent("https://joyjaewon.tistory.com/rss");
  const apiKey = "jdjvfvwqpw2jyhw1z2aahn5iwml8qhslxitdrhqv";

  useEffect(() => {
    const getData = async () => {
      setLoading(true); // 로딩 시작
      try {
        const result = await axios.get(
          `https://api.rss2json.com/v1/api.json?rss_url=${encoded}&api_key=${apiKey}`
        );
        console.log("Get data!");
        setPost(result.data.items); // 이미지 URL 추출 불필요
      } catch (error) {
        console.log("Data load failed:" + error);
      } finally {
        setLoading(false); // 로딩 종료
      }
    };
    getData();
  }, [encoded, apiKey]);

  return (
    <>
      {loading ? (
        <div>Please wait...</div> // 로딩 중 메시지 표시
      ) : (
        <>
          <h2 className={style.blog_title}>Recent Posts from Blog ✍️</h2>
          <div className={style.grid_container}>
            {post &&
              post.map((item, idx) => {
                return (
                  <div key={idx} className={style.post}>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Note>
                        <div className={style.post_content}>
                          <div
                            className={style.img_wrap}
                            style={{
                              backgroundImage: `url(${item.thumbnail})`,
                            }} // thumbnail 사용
                          />
                          <h3 className={style.post_title}>{item.title}</h3>
                          <p className={style.pubdate}>{item.pubDate}</p>
                        </div>
                      </Note>
                    </a>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </>
  );
};

export default Blog;
