import CodeBlock from "./../components/CodeBlock";
import CodeLine from "./../components/CodeLine";
import TagContents from "../components/TagContents";
import { TagOpen, TagClose } from "./../components/Tags";
import style from "./../styles/About.module.css";

const About = () => {
  return (
    <>
      <div className={style.circlecontainer}>
        <div className={style.circle}>
          <img
            className={style.memoji}
            src={`/images/profile.jpg`}
            alt="profile-pic"
          />
        </div>
      </div>
      <CodeBlock>
        <CodeLine>
          <TagOpen tagname={"html"} attr={"lang"} attrValue={'"en"'} />
        </CodeLine>

        <CodeLine>
          <TagOpen tagname={"head"} indent={3} />
        </CodeLine>

        <CodeLine>
          <TagOpen tagname={"title"} indent={4} />
        </CodeLine>

        <CodeLine>
          <TagContents indent={4}>
            Hello and Welcome!👋 My name is Jaewon Han, and I'm a Full Stack
            Software Engineer based in Dallas, TX.
            <br />
            Over the years, I've developed a strong expertise in building
            scalable, high-performance front-end and back-end applications,
            focusing on delivering clean, maintainable code that drives
            exceptional user experiences.
          </TagContents>
        </CodeLine>
        <CodeLine>
          <TagClose tagname={"title"} indent={4}></TagClose>
        </CodeLine>
        <CodeLine>
          <TagClose tagname={"head"} indent={3} />
        </CodeLine>
        <CodeLine>
          <TagOpen tagname={"body"} indent={3}></TagOpen>
        </CodeLine>
        <CodeLine>
          <TagContents indent={4}>
            {" "}
            As a developer, I'm passionate about crafting solutions that blend
            seamless functionality with intuitive design. I lead
            cross-functional teams to execute complex features from concept to
            deployment, collaborating closely with product managers, designers,
            and data scientists to deliver high-impact solutions.
            <br /> Currently, I serve as a Software Engineer at Calyx
            Software, where I architect and maintain loan origination platforms
            using AngularJS, C#, and RESTful APIs. I have driven major
            initiatives such as transforming legacy systems into modern,
            responsive designs and implementing performance optimizations that
            significantly improved user engagement.
          </TagContents>
        </CodeLine>
        <CodeLine>
          <TagContents indent={4}>
            <br />
            Beyond my professional work, I hold a Master’s degree in Computer
            Science from the University of Texas at Dallas, with advanced
            knowledge in Machine Learning, Big Data, and Distributed Systems. My
            deep understanding of algorithms and data structures allows me to
            solve complex challenges efficiently while scaling systems to handle
            increased loads.
          </TagContents>
        </CodeLine>
        <CodeLine>
          <TagContents indent={4}>
            <br />
            Feel free to reach out if you'd like to discuss how I can bring
            value to your project or team. I’m always excited to take on new
            challenges and explore innovative technologies.
          </TagContents>
        </CodeLine>
        <CodeLine>
          <TagClose tagname={"body"} indent={3}></TagClose>
        </CodeLine>
        <CodeLine>
          <TagClose tagname={"html"} />
        </CodeLine>
      </CodeBlock>
    </>
  );
};

export default About;
