import { useNavigate } from "react-router-dom";

import VscodeIcon from "./../components/icons/VscodeIcon";

import style from "./../styles/Home.module.css";

const Home = () => {
  const navigate = useNavigate();
  return (
    <div className={style.home}>
      <div className={style.inner}>
        <VscodeIcon className={style.icon_vscode} />
        <div className={style.typewriter}>
          <h1>Hello 'Jaewon' World!</h1>
        </div>
        <p className={style.heading}>
          A Full Stack Software Engineer with a Passion for Frontend
          <br />{" "}
        </p>
        <div className={style.buttons}>
          <button
            onClick={() => {
              navigate("/projects");
            }}
            className={`${style.button} ${style.button_work}`}
          >
            View Works
          </button>
          <a href="/Jaewon-Han-Resume.pdf" download>
            <button className={`${style.button} ${style.button_resume}`}>
              Download Resume
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
